import React from "react";
import { graphql } from "gatsby"
import ContactUs from "../components/contact-us";

const ContactUsPage = ({ data }) => {
  return <ContactUs data={data} />;
};

export default ContactUsPage;

export const query = graphql`
  {
      contentfulContactUsFormPage {
        backgroundImage {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        title {
          title
        }
        subTitle {
          subTitle
        }
        phoneNumber
        questions {
          questionList {
            label
            value
          }
        }
      }
     allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
  }
`
