import React, { useState } from "react";
import BackgroundImage from "gatsby-background-image";
import axios from "axios";

const ContactUs = ({ data }) => {
  const { contentfulContactUsFormPage } = data;
  const [contactDetails, setContactDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    howCanWeHelp: contentfulContactUsFormPage?.questions?.questionList[0]?.value
  });
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmpty = Object.values(contactDetails).some(field => field === null || field === '')
    if (!isEmpty) {
      axios.post('https://api.lqam.com/users/contact-us', contactDetails).then((response) => {
        if (response?.data?.statusCode === 200) {
          setSuccess(true);
          setContactDetails({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            howCanWeHelp: ''
          })
        } else if (response?.data?.statusCode === 500) {
        }
      }).catch(error => {
      })
    }
  };

  return (
    <>
      <BackgroundImage
        className="banner"
        style={{ backgroundAttachment: "scroll" }}
        Tag="section"
        fluid={contentfulContactUsFormPage.backgroundImage?.fluid}
      />
      <div className="contact-us-form">
        <div className="container">
          <h2 className="theme-title text-center">Contact Us</h2>
          <p>
            {contentfulContactUsFormPage?.title?.title}
            <br />
            {contentfulContactUsFormPage?.subTitle?.subTitle}
            <br />
            Prefer to talk? Call us <a href="tel:18669724000">{contentfulContactUsFormPage?.phoneNumber}</a>
          </p>
          <div className="contact-form">
            <form onSubmit={e => handleSubmit(e)}>
              <div className="row">
                <div className="col-md-6 form-field">
                  <input
                    className="form-control"
                    required
                    type="text"
                    name="firstname"
                    placeholder="First name"
                    value={contactDetails.firstName}
                    onChange={(e) => setContactDetails({ ...contactDetails, 'firstName': e.target.value })}
                  />
                </div>
                <div className="col-md-6 form-field">
                  <input
                    className="form-control"
                    required
                    type="text"
                    name="lastname"
                    placeholder="Last name"
                    value={contactDetails.lastName}
                    onChange={(e) => setContactDetails({ ...contactDetails, 'lastName': e.target.value })}
                  />
                </div>
                <div className="col-md-6 form-field">
                  <input
                    className="form-control"
                    required
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={contactDetails.email}
                    onChange={(e) => setContactDetails({ ...contactDetails, 'email': e.target.value })}
                  />
                </div>
                <div className="col-md-6 form-field">
                  <select className="form-control" id="select-help" required onChange={(e) => {
                    setContactDetails({ ...contactDetails, 'howCanWeHelp': e.target.value })
                  }
                  }>
                    {
                      contentfulContactUsFormPage?.questions?.questionList.map((item, index) => {
                        return <option key={index} value={item.value}>{item.label}</option>
                      })
                    }

                  </select>
                </div>
                <div className="col-md-12 form-field">
                  <textarea
                    required
                    className="form-control"
                    id="contact-message"
                    rows="8"
                    placeholder="Message"
                    value={contactDetails.message}
                    onChange={e => setContactDetails({ ...contactDetails, 'message': e.target.value })}
                  ></textarea>
                </div>
                {
                  success ? <p style={{ textAlign: 'left', color: '#898888', fontSize: 16 }}>Your request has been sent successfully.</p> : null
                }
                <div className="submit-btn">
                  <input
                    type="submit"
                    className="theme-button"
                    value="SUBMIT"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
